import React from "react"
import lekkerhome from "../assets/images/lekker-home.png"
import bulksupplements from "../assets/images/bulksupplements.png"
import italic from "../assets/images/italic.png"
import afloral from "../assets/images/afloral.png"

function TrustedByBanner() {
  return (
    <div className={`row four-columns`}>
      <h2 className="text-center">Trusted By</h2>

      <div className="col-md-3 text-center">
        <img
          src={lekkerhome}
          alt="Lekker Home"
          className="img-fluid"
          width="300"
        />
      </div>
      <div className="col-md-3 text-center">
        <img
          src={bulksupplements}
          alt="Bulk Supplements"
          className="img-fluid"
          width="300"
        />
      </div>
      <div className="col-md-3 text-center">
        <img src={italic} alt="Italic" className="img-fluid" width="300" />
      </div>
      <div className="col-md-3 text-center">
        <img src={afloral} alt="Afloral" className="img-fluid" width="300" />
      </div>
    </div>
  )
}

export default TrustedByBanner
